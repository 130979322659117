import React, { useEffect, useState } from "react";

// @mui-components
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Fab,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
} from "@mui/material";

//components
import Iconify from "../../../components/iconify";

//externals
import PropTypes from "prop-types";
import secureLocalStorage from "react-secure-storage";

//bootstrap-components
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

//services
import { Icon } from "@iconify/react";
import ApiCalls from "../../../services/try";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

MeasurementList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function MeasurementList({
  title,
  subheader,
  measurements,
  setMeasurements,
  patientId,
  loading,
  setLoading,
  onRefresh,
  selectedDurationFilterDash,
  selectedParamFilterDash,
  selectedDateFilterDash,
  ...other
}) {
  // Function to format date according to the user's local timezone in 'dd-mm-yyyy hh:mm:ss AM/PM' format
  const formatToLocalTime = (timestamp) => {
    const date = new Date(timestamp);

    // Extract date parts
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();

    // Extract time parts and convert to 12-hour format
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight
    const formattedHours = String(hours).padStart(2, "0");

    // Combine parts into desired format with AM/PM
    return `${day}-${month}-${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  };

  let userFeed = measurements.map(function (measures) {
    // Format the measurement timestamp to the local timezone with AM/PM
    const formattedTime = formatToLocalTime(measures.measTimeStamp);

    // Split the readingValues based on whether it contains a comma or a slash
    var roundedData;
    if (measures.readingValues && measures.readingValues.includes("/")) {
      // Display the original value as it is for "128.0/93.0" format
      roundedData = measures.readingValues;
    } else {
      // Handle the regular case with comma separation
      roundedData =
        measures.readingValues &&
        measures.readingValues
          .split(",")
          .map(Number)
          .map((num) => num.toFixed(2))
          .join(",");
    }

    const row = {
      measureId: measures.measurementid,
      name: measures.deviceName,
      paramName: measures.paramName,
      meastime: formattedTime, // Use formatted time here
      value:
        measures.paramName === "Panic" ? (
          <p
            style={{ color: "red", fontFamily: "calibri", fontWeight: "bold" }}
          >
            {" "}
            {measures.paramName + " Occurred"}
          </p>
        ) : measures.paramName === "Motion" ? (
          measures.paramName + " Detected"
        ) : (
          roundedData
        ),
    };
    return row;
  });

  const data = {
    columns: [
      {
        label: "Measurement Id",
        field: "measureId",
      },
      {
        label: secureLocalStorage.getItem("DeviceName"),
        field: "name",
      },
      {
        label: "Parameter",
        field: "paramName",
      },
      {
        label: "Measured Timestamp",
        field: "meastime",
      },
      {
        label: "Value",
        field: "value",
      },
    ],
    rows: userFeed,
  };

  const [selectedParamFilter, setSelectedParamFilter] = useState([]);
  const [parameters, setParameters] = useState([]);

  const [openDateFilter, setOpenDateFilter] = useState(null);
  const [openParamFilter, setOpenParamFilter] = useState(null);
  const [paramName, setParamName] = useState("");

  const [filterOptions, setFilterOptions] = useState([
    {
      value: "all",
      label: "All",
    },
    {
      value: "today",
      label: "Today",
    },
    {
      value: "week",
      label: "One Week",
    },
    {
      value: "month",
      label: "One Month",
    },
    // {
    //   value: 'date',
    //   label: 'Selected Dates',
    // },
    // {
    //   value: 'specificdate',
    //   label: 'Date',
    // },
  ]);
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    filterOptions[0]
  );

  useEffect(() => {
    console.log("selectedParamFilterDash: ", selectedParamFilterDash);
    console.log("Entered To get The param Name" + patientId);
    ApiCalls.getdetails(
      "patientId/" + patientId + "/getmeasurementparameter"
    ).then((res) => {
      console.log("Parameter Name");
      console.log("*********************");
      console.log(res.data.measure);
      if (res.data.measure !== undefined) {
        console.log(res.data.measure[0]);
        setParamName(res.data.measure[0].paramName);
        const options = res.data.measure.map((d) => ({
          label: d.paramName,
          value: d.paramid,
        }));
        const allOption = { label: "All", value: "all" };
        options.unshift(allOption);
        setParameters(options);
        setSelectedParamFilter(options[0]);
      }
    });
  }, [patientId]);

  const loadMeasurements = () => {
    setLoading(true);
    var parameterName = "all";
    ApiCalls.getdetails(
      "patient/" +
        patientId +
        "/parameter/" +
        parameterName +
        "/getmeasurementdetails"
    )
      .then((res) => {
        if (res.data.measurement !== undefined) {
          setMeasurements(res.data.measurement);
          setSelectedDateFilter(filterOptions[0]);
          setSelectedParamFilter(parameters[0]);
          setLoading(false); // Hide loading state
        } else {
          setMeasurements([]);
          setLoading(false); // Hide loading state
        }
      })
      .catch((error) => {
        setMeasurements([]);
        setLoading(false); // Hide loading state
      });
  };

  const handleOpenDateFilters = (e) => {
    setOpenDateFilter(e.currentTarget);
  };

  const handleCloseDateFilters = () => {
    setOpenDateFilter(null);
  };

  const handleDateFilterChange = (filter) => {
    console.log("Select Filter");
    console.log(filter.value);
    setSelectedDateFilter(filter);
    setLoading(true);
    loadFilterMeasurements(selectedParamFilter.label, filter.value);
    handleCloseDateFilters();
  };

  const handleOpenParamFilters = (e) => {
    setOpenParamFilter(e.currentTarget);
  };

  const handleCloseParamFilters = () => {
    setOpenParamFilter(null);
  };
  const handleParamFilterChange = (filter) => {
    console.log("Select Filter");
    console.log(filter);
    setSelectedParamFilter(filter);
    setLoading(true);
    loadFilterMeasurements(filter.label, selectedDateFilter.value);
    handleCloseParamFilters();
  };

  const loadFilterMeasurements = (parameterName, dateFilter) => {
    if (parameterName === "All") {
      ApiCalls.getdetails(
        "patient/" +
          patientId +
          "/parameter/all/" +
          dateFilter +
          "/getmeasurementwithfilter/0/0/0"
      )
        .then((response) => {
          if (response.data.measurement != undefined) {
            console.log("Measurement Details");
            console.log(
              "Measurement Count=" + response.data.measurement.length
            );
            console.log(response.data);
            setMeasurements(response.data.measurement);
            setLoading(false);
          } else {
            setLoading(false);
            setMeasurements([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          // Handle error
          console.log(error);
          setLoading(false);
        });
    } else {
      ApiCalls.getdetails(
        "patient/" +
          patientId +
          "/parameter/" +
          parameterName +
          "/" +
          dateFilter +
          "/getmeasurementwithfilter/0/0/0"
      )
        .then((response) => {
          if (response.data.measurement != undefined) {
            console.log("Measurement Details");
            console.log(
              "Measurement Count=" + response.data.measurement.length
            );
            console.log(response.data);
            setMeasurements(response.data.measurement);
            setLoading(false);
          } else {
            setLoading(false);
            setMeasurements([]);
          }
        })
        .catch((error) => {
          // Handle error
          console.log(error);
          setLoading(false);
        });
    }
  };

  const handleRefresh = () => {
    loadMeasurements();
    onRefresh(
      patientId,
      selectedParamFilterDash.label,
      selectedDateFilterDash.value,
      selectedDurationFilterDash.value
    ); // Call chartMeasurements function with appropriate arguments
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <>
            <Tooltip title="Filter Parameter">
              <Button
                color="primary"
                className="ml-2"
                variant="contained"
                onClick={handleOpenParamFilters}
                endIcon={<Iconify icon="material-symbols:filter-alt" />}
              >
                {selectedParamFilter.label}
              </Button>
            </Tooltip>
            <Tooltip title="Filter Date">
              <Button
                className="ml-2"
                color="primary"
                variant="contained"
                onClick={handleOpenDateFilters}
                endIcon={
                  <Iconify icon="material-symbols:filter-list-rounded" />
                }
              >
                {selectedDateFilter.label}
              </Button>
            </Tooltip>
            <Tooltip title="Refresh">
              <Fab
                size="small"
                color="primary"
                className="ml-2 mr-2"
                onMouseDown={handleMouseDown}
                onClick={handleRefresh}
              >
                <Icon icon="mdi:refresh" width="25" />
              </Fab>
            </Tooltip>
          </>
        }
      />
      <Popover
        open={Boolean(openDateFilter)}
        anchorEl={openDateFilter}
        onClose={handleCloseDateFilters}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {filterOptions.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedDateFilter.value}
              onClick={() => handleDateFilterChange(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>

      <Popover
        open={Boolean(openParamFilter)}
        anchorEl={openParamFilter}
        onClose={handleCloseParamFilters}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: "auto", // Changed from fixed width to auto
            maxWidth: "300px", // Added maximum width
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              whiteSpace: "normal", // Allow text to wrap
              wordBreak: "break-word", // Break long words if necessary
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {parameters.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedParamFilter.value}
              onClick={() => handleParamFilterChange(option)}
              sx={{
                minHeight: "auto", // Allow MenuItem to adjust its height
                py: 1, // Add some vertical padding
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>

      <Box sx={{ mx: 3 }} dir="ltr">
        {loading ? (
          <MDBTable
            striped
            borderless
            hover
            responsive
            style={{ marginTop: 15 }}
          >
            <MDBTableHead color="primary-color" textWhite>
              <tr>
                <th>Measurement Id</th>
                <th>{secureLocalStorage.getItem("DeviceName")}</th>
                <th>Parameter</th>
                <th>Measured @</th>
                <th>Reading</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <td colSpan="5">
                  <center>
                    <CircularProgress />
                  </center>
                </td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        ) : (
          <MDBDataTable
            theadColor="primary-color"
            borderless
            entriesOptions={[5, 10, 20, 50, 100]}
            fullpagination="true"
            entries={5}
            tag
            dark={false}
            noBottomColumns
            responsive
            data={data}
            processing={loading}
            striped
            btn={true}
            sortable={false}
            noRecordsFoundLabel={<center>No Measurements Found</center>}
          />
        )}
      </Box>
    </Card>
  );
}
