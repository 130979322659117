import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import {
    Bed,
    Close,
    Delete,
    DevicesOutlined,
    Edit,
    FirstPage,
    Home,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage
} from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Popover,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import secureLocalStorage from "react-secure-storage";
import swal from 'sweetalert';

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        "&:hover, &:focus": {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        "&:active": {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography component="div" sx={{ p: 3 }}>
                    {children}
                </Typography>
            )}
        </div>
    );
}

// ----------------------------------------------------------------------

const ManagePatient = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [patient, setPatient] = useState([]);
    const [expandedCards, setExpandedCards] = useState([]);
    const [beds, setBeds] = useState([]);
    const [devices, setDevices] = useState([]);
    const [selectedBedToAssign, setSelectedBedToAssign] = useState([]);
    const [devicesAssgined, setDevicesAssigned] = useState([]);
    const [patientprivilege, setPatientPrivilege] = useState([]);
    const [selectedDevicestoAssign, setSelectedDevicetoAssign] = useState([]);

    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPatientId, setCurrentPatientId] = useState(null);
    const [selectedPatientToAdmit, setSelectedPatientToAdmit] = useState("");
    const [patientAdmission, setPatientAdmission] = useState("");
    const [patientWaveform, setPatientWaveform] = useState("");
    const [patientInfoMsg, setPatientInfoMsg] = useState("");
    const [patientInfo, setPatientInfo] = useState("")
    const [roleName, setRoleName] = useState("");

    const [loading, setLoading] = useState(false);
    const [bedLoading, setBedLoading] = useState(false);
    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [redirectToView, setRedirectToView] = useState(false);
    const [redirectToCMSView, setRedirectToCMSView] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDevDialog, setOpenDevDialog] = useState(false);
    const [loadDeviceAssigned, setLoadDeviceAssigned] = useState(true);
    const [loadDevicesList, setLoadDevicesList] = useState(true);

    const loadPatientList = () => {
        setLoading(true);
        var companyId = secureLocalStorage.getItem("compId");
        var rolename = secureLocalStorage.getItem("rolename");
        var tenantId = secureLocalStorage.getItem("tenantId");
        var physicianId = "0";
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = secureLocalStorage.getItem("compId");
            physicianId = "0";
        }
        if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("UUID");
            physicianId = "0";
        }
        if (rolename === "Physician") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("companyId");
            physicianId = secureLocalStorage.getItem("UUID");
        }

        var roleId = secureLocalStorage.getItem("roleId");

        if (roleId !== "3" || roleId != 3) {
            ApiCalls.getdetails(
                "roleId/" + roleId + "/getpatientprivilegedetails"
            ).then((response) => {
                console.log(response.data);
                if (response.data.patientprivilege != undefined) {
                    const patientInfo = response.data.patientprivilege[0].patientInfo;
                    console.log("PatientInfo: " + patientInfo);

                    const patientAdmission =
                        response.data.patientprivilege[0].patientAdmission;
                    console.log("patientAdmission: " + patientAdmission);
                    const patientWaveform =
                        response.data.patientprivilege[0].patientWaveform;
                    console.log("patientWaveform: " + patientWaveform);
                    setPatientPrivilege(response.data.patientprivilege);
                    setPatientAdmission(patientAdmission);
                    setPatientWaveform(patientWaveform);
                    setPatientInfo(patientInfo);

                    if (patientInfo === "true") {
                        // Only proceed if patientInfo is "true"
                        setPatientInfoMsg(
                            " No " + secureLocalStorage.getItem("PatientName") + " found"
                        );
                        ApiCalls.getdetails(
                            "organizations/0/tenants/" +
                            tenantId +
                            "/companies/" +
                            companyId +
                            "/physicians/" +
                            physicianId +
                            "/patients"
                        )
                            .then((response) => {
                                console.log(response.data);

                                if (response.data.patient != undefined) {
                                    const updatedPatients = response.data.patient.map(
                                        (patient) => {
                                            return ApiCalls.getdetails(
                                                "patientId/" + patient.patientuuid + "/bedById"
                                            )
                                                .then((bedResponse) => {
                                                    console.log(
                                                        "Bed details for patientId " +
                                                        patient.firstName +
                                                        ":",
                                                        bedResponse.data
                                                    );

                                                    if (bedResponse.data.beds && bedResponse.data.beds.length > 0) {
                                                        const mostRecentBed = bedResponse.data.beds.reduce((latest, current) => {
                                                            return (!latest.admittedTime || current.admittedTime > latest.admittedTime) ? current : latest;
                                                        });
                                                        patient.mapStatus = mostRecentBed.mapStatus;
                                                        patient.bedId = mostRecentBed.bedId;
                                                        patient.wardNo = mostRecentBed.wardNo;
                                                        patient.bedNo = mostRecentBed.bedNo;
                                                    } else {
                                                        patient.mapStatus = "NotFound";
                                                        patient.bedId = null;
                                                    }

                                                    return patient;
                                                })
                                                .catch((bedError) => {
                                                    console.error(
                                                        "Error fetching bed details for patientId " +
                                                        patient.firstName +
                                                        ":",
                                                        bedError
                                                    );
                                                    patient.mapStatus = "Error";
                                                    return patient;
                                                });
                                        }
                                    );

                                    Promise.all(updatedPatients)
                                        .then((patientsWithStatus) => {
                                            setPatient(patientsWithStatus);
                                            console.log("Updated patients:", patientsWithStatus);
                                            setLoading(false);
                                        })
                                        .catch((error) => {
                                            console.log("Error processing patients:", error);
                                            setLoading(false);
                                            setPatient([]);
                                            setPatientInfoMsg(
                                                " No " +
                                                secureLocalStorage.getItem("PatientName") +
                                                " found"
                                            );
                                        });
                                } else {
                                    setLoading(false);
                                    setPatient([]);
                                    setPatientInfoMsg(
                                        " No " +
                                        secureLocalStorage.getItem("PatientName") +
                                        " found"
                                    );
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                setLoading(false);
                                setPatient([]);
                                setPatientInfoMsg(
                                    " No " + secureLocalStorage.getItem("PatientName") + " found"
                                );
                            });
                    } else {
                        // If patientInfo is not "true", show a message
                        console.log("No permission to view patient details");
                        setPatientInfoMsg("No permission to view patient details");
                        setLoading(false);
                        setPatient([]);
                    }
                } else {
                    setPatientPrivilege([]);
                }
            });
        } else {
            console.log("No permission to view patient details");
            setPatientInfoMsg("No permission to view patient details");
            setLoading(false);
            setPatient([]);
        }
    };

    useEffect(() => {
        var rolename = secureLocalStorage.getItem("rolename");
        setRoleName(rolename);
        secureLocalStorage.removeItem("PatientToView");
        loadPatientList();
    }, []);

    const filteredPatients = patient.filter((patients) => {
        const firstName = patients.firstName ? patients.firstName.toLowerCase() : '';
        const lastName = patients.lastName ? patients.lastName.toLowerCase() : '';
        const searchTermLower = searchTerm.toLowerCase();
        return firstName.includes(searchTermLower) || lastName.includes(searchTermLower);
    });


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - patient.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    const handleExpandClick = (index) => {
        setExpandedCards((prevExpandedCards) => {
            if (prevExpandedCards.includes(index)) {
                return prevExpandedCards.filter((i) => i !== index);
            } else {
                return [index];
            }
        });
    };

    const handleDeleteAdmittedPatient = () => {
        swal({
            title: "Cannot Delete",
            text: `This ${secureLocalStorage.getItem(
                "PatientName"
            )} cannot be deleted at the moment as they are currently admitted.`,
            icon: "warning",
            buttons: {
                ok: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "btn-danger",
                    closeModal: true,
                },
            },
            dangerMode: true,
        });
    };

    const handleDeletePatient = (patientId) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                var roleId = secureLocalStorage.getItem("roleId");
                var uuid = secureLocalStorage.getItem("tenantId");
                if (roleId == 6 || roleId == 7) {
                    var data = { physicianId: uuid, patientuuid: patientId };
                } else if (roleId == 4 || roleId == 5) {
                    var data = { companyId: uuid, patientuuid: patientId };
                } else {
                    var data = { tenantId: uuid, patientuuid: patientId };
                }

                console.log(data);
                var rolename = secureLocalStorage.getItem("rolename");
                if (rolename === "Tenant") {
                    roleId = "2";
                }
                if (rolename === "Physician") {
                    roleId = "6";
                }
                ApiCalls.delete(
                    "organizations/0/tenants/" +
                    uuid +
                    "/patients/" +
                    patientId +
                    "/deletepatient"
                ).then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Deleted Successfully!", {
                            icon: "success",
                        });
                        loadPatientList();
                    }
                });
            }
        });
    };

    const PATIENT_ID_KEY = "currentPatientId";
    const PATIENT_TO_EDIT_KEY = "patientIdToEdit";
    const PATIENT_TO_VIEW_KEY = "PatientToView";

    const handleOpenMenu = (event, patientId) => {
        console.log("Open Menu for Patient:", patientId);
        secureLocalStorage.setItem(PATIENT_TO_VIEW_KEY, patientId);
        setCurrentPatientId(patientId); // Set current patient ID
        setOpen(event.currentTarget);
    };

    const handleEditPatient = (patientId) => {
        console.log("Editing patient:", patientId);
        secureLocalStorage.setItem(PATIENT_TO_EDIT_KEY, patientId);
        console.log("Stored patient ID for edit:", secureLocalStorage.getItem(PATIENT_TO_EDIT_KEY));
        setRedirectToEdit(true);
    };

    const handleClickPatientView = (event, patientId) => {
        console.log("Viewing patient:", patientId);
        secureLocalStorage.setItem(PATIENT_TO_VIEW_KEY, patientId);
        console.log("Stored patient ID for view:", secureLocalStorage.getItem(PATIENT_TO_VIEW_KEY));
        setRedirectToView(true);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleClickCMSView = () => {
        setRedirectToCMSView(true);
    };

    const handleDischargePatient = (patient) => {
        let bedId = patient.bedId;
        let patientId = patient.patientuuid;

        swal({
            title: "Are you sure?",
            text: `Do you want to discharge this Patient from ${patient.wardNo} - ${patient.bedNo}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                ApiCalls.delete(
                    "bed/" + bedId + "/patient/" + patientId + "/dischargePatient"
                )
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal(secureLocalStorage.getItem("PatientName") + " Discharged!", {
                                icon: "success",
                            });
                            loadPatientList();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    };

    const handleAdmitPatient = (patient) => {
        console.log(patient);
        setSelectedPatientToAdmit(patient.patientuuid);
        let tenantId = secureLocalStorage.getItem("tenantId");
        let companyId = secureLocalStorage.getItem("compId");
        let rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
        } else if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("UUID");
        }
        ApiCalls.getdetails(
            "tenant/" + tenantId + "/company/" + companyId + "/bedstatus?"
        )
            .then((result) => {
                console.log(result.data);

                if (result.data.beds !== undefined) {
                    const bedsData = result.data.beds;

                    // Group beds based on bedId
                    const groupedBedsMap = new Map();

                    bedsData.forEach((bed) => {
                        const bedId = bed.bedId;

                        if (!groupedBedsMap.has(bedId)) {
                            groupedBedsMap.set(bedId, []);
                        }

                        groupedBedsMap.get(bedId).push(bed);
                    });

                    // Filter and select beds based on the specified conditions
                    const selectedBeds = Array.from(groupedBedsMap.values())
                        .map((bedsGroup) => {
                            // Check if there is any bed with "active" status
                            const hasActiveStatus = bedsGroup.some(
                                (bed) => bed.mapStatus === "active"
                            );

                            // If all beds have "inactive" status, or there are no "active" statuses, select the beds
                            if (
                                !hasActiveStatus ||
                                bedsGroup.every((bed) => bed.mapStatus === "inactive")
                            ) {
                                return bedsGroup[0]; // Select the first bed in the group
                            }

                            return null; // Exclude this group
                        })
                        .filter((selectedBed) => selectedBed !== null);

                    // Select beds that are not repeating the bedId and have "inactive," "Vacant," or "active" status
                    const nonRepeatingBeds = bedsData.filter((bed) => {
                        const bedId = bed.bedId;

                        return (
                            !groupedBedsMap.has(bedId) &&
                            (bed.mapStatus === "inactive" ||
                                bed.mapStatus === "vacant" ||
                                bed.mapStatus === "active")
                        );
                    });

                    // Combine selected beds and non-repeating beds
                    const finalSelectedBeds = [...selectedBeds, ...nonRepeatingBeds];

                    if (finalSelectedBeds.length > 0) {
                        // Handle final selected beds here
                        console.log("Final Selected Beds:", finalSelectedBeds);
                    }

                    setBeds(finalSelectedBeds);
                    setBedLoading(false);
                    setOpenDialog(true);
                } else {
                    setBeds([]);
                    setBedLoading(false);
                    setOpenDialog(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setBeds([]);
                setBedLoading(false);
                setOpenDialog(true);
            });
    };

    const handleCloseDialog = () => {
        // Close the dialog
        setOpenDialog(false);
        setSelectedBedToAssign([])
    };

    const handleBedSelection = (e, bed) => {
        const checked = e.target.checked;
        const bedId = bed.bedId;
        const bedNo = bed.bedNo;
        const wardNo = bed.wardNo;

        if (checked) {
            // Uncheck all other checkboxes before checking the current one
            setSelectedBedToAssign([{ id: bedId, bedNo: bedNo, wardNo: wardNo }]);

            ApiCalls.getdetails("bed/" + bedId + "/getdeviceassigned")
                .then((response) => {
                    console.log(response.data);
                    const data = response.data.device;
                    console.log(data);

                    if (data !== undefined && data.length > 0) {
                        // Devices are assigned, show confirmation to admit the patient
                        swal({
                            title: "Are you sure?",
                            text: `You want to admit this patient to ${wardNo} - ${bedNo}?`,
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                // Your logic to handle patient admission with assigned devices
                                let apiData = {
                                    patientId: selectedPatientToAdmit,
                                    wardNo: wardNo,
                                    bedNo: bedNo,
                                };

                                ApiCalls.register("bed/" + bedId + "/patient/assign", apiData)
                                    .then((response) => {
                                        console.log(response.data);
                                        if (response.data.status.message === "Success") {
                                            swal(
                                                secureLocalStorage.getItem("PatientName") + " Assigned",
                                                {
                                                    icon: "success",
                                                }
                                            );
                                            setOpenDialog(false);
                                            loadPatientList();
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error.message);
                                        if (
                                            error.message === "Request failed with status code 409"
                                        ) {
                                            swal(
                                                secureLocalStorage.getItem("PatientName") +
                                                " Already Assigned",
                                                {
                                                    icon: "warning",
                                                }
                                            );
                                        }
                                    });
                            }
                        });
                    } else {
                        // No devices assigned, show a different confirmation
                        swal({
                            title: "No Devices Assigned",
                            text: `Currently, there are no devices assigned to ${wardNo} - ${bedNo}.`,
                            icon: "warning",
                            buttons: {
                                cancel: "Cancel",
                                ok: "Admit",
                                add: {
                                    text: "Add",
                                    value: "add",
                                },
                            },
                            dangerMode: true,
                        }).then((value) => {
                            console.log(value);
                            if (value === "ok") {
                                // Your logic to handle patient admission without assigned devices
                                let apiData = {
                                    patientId: selectedPatientToAdmit,
                                    wardNo: wardNo,
                                    bedNo: bedNo,
                                };
                                ApiCalls.register("bed/" + bedId + "/patient/assign", apiData)
                                    .then((response) => {
                                        console.log(response.data);
                                        if (response.data.status.message === "Success") {
                                            swal(
                                                secureLocalStorage.getItem("PatientName") + " Assigned",
                                                {
                                                    icon: "success",
                                                }
                                            );
                                            setOpenDialog(false);
                                            loadPatientList();
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error.message);
                                        if (
                                            error.message === "Request failed with status code 409"
                                        ) {
                                            swal(
                                                secureLocalStorage.getItem("PatientName") +
                                                " Already Assigned",
                                                {
                                                    icon: "warning",
                                                }
                                            );
                                        }
                                    });
                            } else if (value === "add") {
                                // Your logic for handling the "Add" button
                                setOpenDevDialog(true);
                                var tenantId = secureLocalStorage.getItem("tenantId");
                                var companyId = secureLocalStorage.getItem("compId");
                                var rolename = secureLocalStorage.getItem("rolename");
                                if (rolename === "Tenant") {
                                    tenantId = secureLocalStorage.getItem("UUID");
                                    companyId = "0";
                                } else if (rolename === "Company") {
                                    tenantId = secureLocalStorage.getItem("tenantId");
                                    companyId = secureLocalStorage.getItem("UUID");
                                } else if (rolename === "Physician") {
                                    tenantId = secureLocalStorage.getItem("tenantId");
                                    companyId = secureLocalStorage.getItem("compId");
                                }
                                ApiCalls.getdetails(
                                    "PhysicianId/" + tenantId + "/company/" + companyId + "/showdevice")
                                    .then((response) => {
                                        console.log(response.data);
                                        if (response.data.device !== undefined) {
                                            console.log(response.data.device);
                                            for (var i = 0; i < response.data.device.length; i++) {
                                                setDevices(response.data.device);
                                                setLoadDevicesList(false);
                                            }
                                        } else {
                                            setLoadDevicesList(false);
                                            setDevices([]);
                                        }
                                    }).catch((error) => {
                                        console.log(error);
                                        setLoadDevicesList(false);
                                        setDevices([]);
                                    })

                            }
                        });
                    }

                    setDevicesAssigned(data);
                    setLoadDeviceAssigned(false);
                })
                .catch((err) => {
                    console.log(err);
                    setDevicesAssigned([]);
                    setLoadDeviceAssigned(false);
                });
        } else {
            setSelectedBedToAssign([]);
            // Your existing code to uncheck the checkbox and update the state
            // ...
        }
        console.log(selectedBedToAssign);
    };

    const handleAssignDeviceToBed = () => {
        console.log(selectedBedToAssign);
        let bedId = selectedBedToAssign[0].id;
        var a = [];
        var list = selectedDevicestoAssign.length;
        for (var i = 0; i < list; i++) {
            var counter = selectedDevicestoAssign[i];
            const deviceuuid = counter.id;
            const devicemacId = counter.macid;
            var s = { deviceuuid: deviceuuid, devmacId: devicemacId };
            a.push(s);
        }
        var data = {
            devices: a,
        };


        if (selectedDevicestoAssign.length > 0) {
            ApiCalls.register("bed/" + bedId + "/device/assign", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        let apiData = {
                            patientId: selectedPatientToAdmit,
                            wardNo: selectedBedToAssign.wardNo,
                            bedNo: selectedBedToAssign.bedNo,
                        };
                        ApiCalls.register("bed/" + bedId + "/patient/assign", apiData)
                            .then((response) => {
                                console.log(response.data);
                                if (response.data.status.message === "Success") {
                                    swal((secureLocalStorage.getItem("DeviceName") + " Added & " + secureLocalStorage.getItem("PatientName") + " Assigned"), {
                                        icon: "success",
                                    });
                                    handleCloseDevDialog(bedId);
                                    handleCloseDialog();
                                    loadPatientList();
                                }
                            })
                            .catch((error) => {
                                console.log(error.message);
                                if (
                                    error.message === "Request failed with status code 409"
                                ) {
                                    swal(
                                        secureLocalStorage.getItem("PatientName") +
                                        " Already Assigned",
                                        {
                                            icon: "warning",
                                        }
                                    );
                                }
                            });

                    } else if (
                        response.data.status.details === "Device Already Assigned To Bed"
                    ) {
                        swal((secureLocalStorage.getItem("DeviceName") + " Already Assigned"), {
                            icon: "warning",
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } else {
            swal({
                title: "Warning",
                text: "Select A Device",
                icon: "info"
            })
        }
    }

    const handleCloseDevDialog = () => {
        // Close the dialog
        setOpenDevDialog(false);
    };

    if (redirectToEdit) {
        return <Redirect to={"/editpatient"} />;
    }

    if (redirectToForm) {
        return <Redirect to={"/registerpatient"} />;
    }

    if (redirectToView) {
        return <Redirect to={"/patientview"} />;
    }

    if (redirectToCMSView) {
        return <Redirect to={"/patientcms"} />;
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button
                                variant="text"
                                startIcon={<Home fontSize="small" />}
                                onMouseDown={handleMouseDown}
                                sx={{ mr: 1 }}
                            >
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb
                            label={"Manage " + secureLocalStorage.getItem("PatientName")}
                        />
                    </Breadcrumbs>
                    <Button
                        sx={{
                            display: roleName === "Physician" || roleName === "Tenant" ? "none" : "",
                        }}
                        variant="contained"
                        onClick={() => setRedirectToForm(true)}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        New {secureLocalStorage.getItem("PatientName")}
                    </Button>
                </Stack>

                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h4" gutterBottom>
                                {secureLocalStorage.getItem("PatientName")} List
                            </Typography>
                        }
                        action={
                            <TextField
                                label={"Search " + secureLocalStorage.getItem("PatientName")}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        {loading ? (
                            <center>
                                <CircularProgress />
                            </center>
                        ) : filteredPatients.length > 0 ? (
                            <>
                                <Tabs
                                    value={tabValue}
                                    onChange={(event, newValue) => setTabValue(newValue)}
                                    onMouseDown={handleMouseDown}
                                    sx={{
                                        "& .MuiTab-root": { minWidth: "auto", fontSize: "1.2rem" },
                                    }}
                                    variant="fullWidth"
                                >
                                    <Tab
                                        icon={
                                            <Icon
                                                icon={"material-symbols:recent-patient-outline-rounded"}
                                                width="40"
                                            />
                                        }
                                        label={"Out " + secureLocalStorage.getItem("PatientName")}
                                    />
                                    <Tab
                                        icon={<Icon icon={"medical-icon:i-inpatient"} width="40" />}
                                        label={"In " + secureLocalStorage.getItem("PatientName")}
                                    />
                                </Tabs>

                                <TabPanel value={tabValue} index={0}>
                                    {filteredPatients.length > 0 ? (
                                        <Grid container spacing={3}>
                                            {filteredPatients.map(
                                                (patient) =>
                                                    (patient.mapStatus === "NotFound" ||
                                                        patient.mapStatus === "Discharged") && (
                                                        <Grid item xs={12} sm={6} md={4}>
                                                            <Card
                                                                sx={{
                                                                    minWidth: 300,
                                                                    minHeight: 420,
                                                                    boxShadow: 3,
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "space-between",
                                                                    backgroundColor: "#F4F6F8",
                                                                    "&:hover": {
                                                                        border: "2px solid #D1E9FC",
                                                                        boxShadow: "none",
                                                                    },
                                                                }}
                                                                key={patient.patientuuid}
                                                            >
                                                                <CardHeader
                                                                    avatar={
                                                                        <Avatar
                                                                            alt={
                                                                                patient.firstName +
                                                                                " " +
                                                                                patient.lastName
                                                                            }
                                                                            src={patient.image}
                                                                            sx={{
                                                                                width: 75,
                                                                                height: 75,
                                                                                backgroundColor: (theme) =>
                                                                                    theme.palette["primary"].light,
                                                                            }}
                                                                        />
                                                                    }
                                                                    action={
                                                                        secureLocalStorage.getItem("rolename") === 'Company' ? (
                                                                            <IconButton
                                                                                onClick={(e) => handleOpenMenu(e, patient.patientuuid)}
                                                                                onMouseDown={handleMouseDown}
                                                                                sx={{ float: "right" }}
                                                                            >
                                                                                <Iconify icon={"mi:options-horizontal"} />
                                                                            </IconButton>
                                                                        ) : null
                                                                    }
                                                                />
                                                                <CardContent sx={{ flexGrow: 1 }}>
                                                                    <Box sx={{ mt: 2, mb: 2 }}>
                                                                        <Typography
                                                                            variant="h5"
                                                                            sx={{ color: "text.primary" }}
                                                                        >
                                                                            {(patient.firstName ? patient.firstName : '') +
                                                                                (patient.lastName ? ' ' + patient.lastName : '') || '--'}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            sx={{ color: "text.secondary" }}
                                                                        >
                                                                            {patient.email}
                                                                        </Typography>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            mb: 2,
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            justifyContent: "space-around",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            sx={{ color: "text.secondary" }}
                                                                        >
                                                                            Gender
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            sx={{ color: "text.primary" }}
                                                                        >
                                                                            {patient.gender !== "null" ? patient.gender : "--"}
                                                                        </Typography>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                mb: 2,
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                justifyContent: "space-around",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                sx={{ color: "text.secondary" }}
                                                                            >
                                                                                Phone
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                sx={{ color: "text.primary" }}
                                                                            >
                                                                                {patient.phone}
                                                                            </Typography>
                                                                        </Box>

                                                                        <Box
                                                                            sx={{
                                                                                mb: 2,
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                justifyContent: "space-around",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                sx={{ color: "text.secondary" }}
                                                                            >
                                                                                Location
                                                                            </Typography>
                                                                            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                                                                {patient.address && Object.keys(patient.address).length > 0 ? (
                                                                                    <>
                                                                                        {patient.address.city && patient.address.state && patient.address.country ? (
                                                                                            ` ${patient.address.state}, ${patient.address.country}`
                                                                                        ) : (
                                                                                            <>
                                                                                                {patient.address.city && patient.address.state ? (
                                                                                                    `${patient.address.city}, ${patient.address.state}`
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {patient.address.city || ""}
                                                                                                        {patient.address.city && patient.address.state && patient.address.country ? ", " : ""}
                                                                                                        {patient.address.state || ""}
                                                                                                        {patient.address.state && patient.address.country ? ", " : ""}
                                                                                                        {patient.address.country || ""}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    "Not Provided"
                                                                                )}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <Popover
                                                                        open={Boolean(open)}
                                                                        anchorEl={open}
                                                                        onClose={handleCloseMenu}
                                                                        anchorOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "left",
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "right",
                                                                        }}
                                                                        PaperProps={{
                                                                            sx: {
                                                                                p: 1,
                                                                                width: 140,
                                                                                "& .MuiMenuItem-root": {
                                                                                    px: 1,
                                                                                    typography: "body2",
                                                                                    borderRadius: 0.75,
                                                                                },
                                                                            },
                                                                        }}
                                                                    >
                                                                        <MenuItem onClick={(e) => handleClickPatientView(e, currentPatientId)}>
                                                                            <Iconify icon={"mdi:account-details"} sx={{ mr: 1 }} />
                                                                            {secureLocalStorage.getItem("PatientName")} view
                                                                        </MenuItem>
                                                                    </Popover>
                                                                </CardContent>
                                                                <CardActions>
                                                                    {secureLocalStorage.getItem("rolename") === 'Company' ? (
                                                                        <>
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="primary"
                                                                                size="small"
                                                                                fullWidth
                                                                                onMouseDown={handleMouseDown}
                                                                                onClick={() => handleEditPatient(patient.patientuuid)}
                                                                                startIcon={<Edit />}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="error"
                                                                                size="small"
                                                                                fullWidth
                                                                                onMouseDown={handleMouseDown}
                                                                                onClick={() => handleDeletePatient(patient.patientuuid)}
                                                                                startIcon={<Delete />}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </>
                                                                    ) : secureLocalStorage.getItem("rolename") === 'Physician' ? (
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            fullWidth
                                                                            onMouseDown={handleMouseDown}
                                                                            onClick={(e) => handleClickPatientView(e, patient.patientuuid)}
                                                                            startIcon={<Iconify icon={"mdi:account-details"} />}
                                                                        >
                                                                            {secureLocalStorage.getItem("PatientName")} view
                                                                        </Button>
                                                                    ) : null}
                                                                </CardActions>
                                                                <CardActions
                                                                    sx={{
                                                                        display:
                                                                            patientAdmission === "false"
                                                                                ? "none"
                                                                                : "",
                                                                        alignItems: 'flex-end'
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="success"
                                                                        size="small"
                                                                        fullWidth
                                                                        onMouseDown={handleMouseDown}
                                                                        onClick={() => handleAdmitPatient(patient)}
                                                                        startIcon={
                                                                            <Icon
                                                                                icon={"heroicons-outline:user-add"}
                                                                            />
                                                                        }
                                                                    >
                                                                        Admit
                                                                    </Button>
                                                                </CardActions>
                                                            </Card>
                                                        </Grid>
                                                    )
                                            )}
                                        </Grid>
                                    ) : (
                                        <center>{patientInfoMsg}</center>
                                    )}
                                </TabPanel>

                                <TabPanel value={tabValue} index={1}>
                                    {filteredPatients.length > 0 ? (
                                        <Grid container spacing={3}>
                                            {filteredPatients.map(
                                                (patient) =>
                                                    patient.mapStatus === "Admitted" && (
                                                        <Grid item xs={12} sm={6} md={4}>
                                                            <Card
                                                                sx={{
                                                                    minWidth: 300,
                                                                    minHeight: 420,
                                                                    boxShadow: 3,
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "space-between",
                                                                    backgroundColor: "#F4F6F8",
                                                                    "&:hover": {
                                                                        border: "2px solid #D1E9FC",
                                                                        boxShadow: "none",
                                                                    },
                                                                }}
                                                                key={patient.patientuuid}
                                                            >
                                                                <CardHeader
                                                                    avatar={
                                                                        <Avatar
                                                                            alt={
                                                                                patient.firstName +
                                                                                " " +
                                                                                patient.lastName
                                                                            }
                                                                            src={patient.image}
                                                                            sx={{
                                                                                width: 75,
                                                                                height: 75,
                                                                                backgroundColor: (theme) =>
                                                                                    theme.palette["primary"].light,
                                                                            }}
                                                                        />
                                                                    }
                                                                    action={
                                                                        secureLocalStorage.getItem("rolename") === 'Company' ? (
                                                                            <IconButton
                                                                                onClick={(e) => handleOpenMenu(e, patient.patientuuid)}
                                                                                onMouseDown={handleMouseDown}
                                                                                sx={{ float: "right" }}
                                                                            >
                                                                                <Iconify icon={"mi:options-horizontal"} />
                                                                            </IconButton>
                                                                        ) : null
                                                                    }
                                                                />
                                                                <CardContent sx={{ flexGrow: 1 }}>
                                                                    <Box sx={{ mt: 2, mb: 2 }}>
                                                                        <Typography
                                                                            variant="h5"
                                                                            sx={{ color: "text.primary" }}
                                                                        >
                                                                            {patient.firstName +
                                                                                " " +
                                                                                patient.lastName}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            sx={{ color: "text.secondary" }}
                                                                        >
                                                                            {patient.email}
                                                                        </Typography>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            mb: 2,
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            justifyContent: "space-around",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            sx={{ color: "text.secondary" }}
                                                                        >
                                                                            Gender
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            sx={{ color: "text.primary" }}
                                                                        >
                                                                            {patient.gender}
                                                                        </Typography>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                mb: 2,
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                justifyContent: "space-around",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                sx={{ color: "text.secondary" }}
                                                                            >
                                                                                Phone
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                sx={{ color: "text.primary" }}
                                                                            >
                                                                                {patient.phone}
                                                                            </Typography>
                                                                        </Box>

                                                                        <Box
                                                                            sx={{
                                                                                mb: 2,
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                justifyContent: "space-around",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                sx={{ color: "text.secondary" }}
                                                                            >
                                                                                Location
                                                                            </Typography>
                                                                            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                                                                {patient.address && Object.keys(patient.address).length > 0 ? (
                                                                                    <>
                                                                                        {patient.address.city && patient.address.state && patient.address.country ? (
                                                                                            ` ${patient.address.state}, ${patient.address.country}`
                                                                                        ) : (
                                                                                            <>
                                                                                                {patient.address.city && patient.address.state ? (
                                                                                                    `${patient.address.city}, ${patient.address.state}`
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {patient.address.city || ""}
                                                                                                        {patient.address.city && patient.address.state && patient.address.country ? ", " : ""}
                                                                                                        {patient.address.state || ""}
                                                                                                        {patient.address.state && patient.address.country ? ", " : ""}
                                                                                                        {patient.address.country || ""}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    "Not Provided"
                                                                                )}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <Popover
                                                                        open={Boolean(open)}
                                                                        anchorEl={open}
                                                                        onClose={handleCloseMenu}
                                                                        anchorOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "left",
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "right",
                                                                        }}
                                                                        PaperProps={{
                                                                            sx: {
                                                                                p: 1,
                                                                                width: 140,
                                                                                "& .MuiMenuItem-root": {
                                                                                    px: 1,
                                                                                    typography: "body2",
                                                                                    borderRadius: 0.75,
                                                                                },
                                                                            },
                                                                        }}
                                                                    >
                                                                        <MenuItem onClick={(e) => handleClickPatientView(e, currentPatientId)}>
                                                                            <Iconify icon={"mdi:account-details"} sx={{ mr: 1 }} />
                                                                            {secureLocalStorage.getItem("PatientName")} view
                                                                        </MenuItem>
                                                                    </Popover>
                                                                </CardContent>
                                                                <CardActions>
                                                                    {secureLocalStorage.getItem("rolename") === 'Company' ? (
                                                                        <>
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="primary"
                                                                                size="small"
                                                                                fullWidth
                                                                                onMouseDown={handleMouseDown}
                                                                                onClick={() => handleEditPatient(patient.patientuuid)}
                                                                                startIcon={<Edit />}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </>
                                                                    ) : secureLocalStorage.getItem("rolename") === 'Physician' ? (
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            fullWidth
                                                                            onMouseDown={handleMouseDown}
                                                                            onClick={(e) => handleClickPatientView(e, patient.patientuuid)}
                                                                            startIcon={<Iconify icon={"mdi:account-details"} />}
                                                                        >
                                                                            {secureLocalStorage.getItem("PatientName")} view
                                                                        </Button>
                                                                    ) : null}
                                                                </CardActions>
                                                                <CardActions
                                                                    sx={{
                                                                        display:
                                                                            patientAdmission === "false"
                                                                                ? "none"
                                                                                : "",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="error"
                                                                        size="small"
                                                                        fullWidth
                                                                        onMouseDown={handleMouseDown}
                                                                        onClick={() =>
                                                                            handleDischargePatient(patient)
                                                                        }
                                                                        startIcon={
                                                                            <Icon icon={"uiw:user-delete"} />
                                                                        }
                                                                    >
                                                                        Discharge
                                                                    </Button>
                                                                </CardActions>
                                                            </Card>
                                                        </Grid>
                                                    )
                                            )}
                                        </Grid>
                                    ) : (
                                        <center>{patientInfoMsg}</center>
                                    )}
                                </TabPanel>
                            </>
                        ) : (
                            <center>{patientInfoMsg}</center>
                        )}
                    </CardContent>

                    <div>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            fullScreen={fullScreen}
                        >
                            <Card sx={{ minWidth: 500 }}>
                                <CardHeader
                                    title={
                                        <Tabs value={tabValue} indicatorColor="primary">
                                            <Tab
                                                icon={<Bed />}
                                                iconPosition="start"
                                                label="Bed Details"
                                                id="tab-0"
                                                wrapped
                                                sx={{
                                                    fontSize: 18,
                                                }}
                                            />
                                        </Tabs>
                                    }
                                    action={
                                        <IconButton
                                            color="primary"
                                            onMouseDown={handleMouseDown}
                                            onClick={handleCloseDialog}
                                        >
                                            <Close />
                                        </IconButton>
                                    }
                                />
                                <CardContent sx={{
                                    maxHeight: 300,
                                    overflowY: "scroll"
                                }}>
                                    <TabPanel value={tabValue} index={0}>
                                        {
                                            bedLoading ? (
                                                <center>
                                                    <CircularProgress />
                                                </center>
                                            ) : beds.length > 0 ? (
                                                <Grid container spacing={2}>
                                                    {beds
                                                        .map((bed) => (
                                                            <Grid item xs={6} sm={6} md={6} key={bed.bedId}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            name={bed.bedNo}
                                                                            value={bed.bedId}
                                                                            id={bed.bedId}
                                                                            checked={selectedBedToAssign.some((d) => d.id === bed.bedId)}
                                                                            onChange={(e) => bed.mapStatus === 'vacant' && handleBedSelection(e, bed)}
                                                                        />
                                                                    }
                                                                    label={
                                                                        <>
                                                                            <Card
                                                                                key={bed.bedId}
                                                                                sx={{
                                                                                    minWidth: 150,
                                                                                    backgroundColor: bed.mapStatus === 'active' ? "#AFE1AF" : ""
                                                                                }}
                                                                            >
                                                                                <CardContent>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                        }}
                                                                                    >
                                                                                        <Icon icon="tabler:bed-filled" width="60" />
                                                                                    </Box>
                                                                                    <Divider />
                                                                                    <Box
                                                                                        sx={{
                                                                                            alignItems: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <center>
                                                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                                <Iconify icon={'material-symbols:ward-rounded'} /> {" "} : {" "} {bed.wardNo}
                                                                                            </Typography>
                                                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                                <Iconify icon={'icon-park-outline:hospital-bed'} /> {" "} : {" "} {bed.bedNo}
                                                                                            </Typography>
                                                                                        </center>
                                                                                    </Box>
                                                                                </CardContent>
                                                                            </Card>
                                                                        </>
                                                                    }
                                                                />
                                                            </Grid>
                                                        ))}
                                                </Grid>
                                            ) : (
                                                <center>
                                                    No Beds Found
                                                </center>
                                            )
                                        }
                                    </TabPanel>
                                </CardContent>
                            </Card>
                        </Dialog>
                    </div>


                    <div>
                        <Dialog
                            open={openDevDialog}
                            onClose={handleCloseDevDialog}
                            fullScreen={fullScreen}
                        >
                            <Card sx={{ minWidth: 500 }}>
                                <CardHeader
                                    title={
                                        <Tabs value={tabValue} indicatorColor="primary">
                                            <Tab
                                                icon={<DevicesOutlined />}
                                                iconPosition="start"
                                                label={secureLocalStorage.getItem("DeviceName") + " Details"}
                                                id="tab-0"
                                                wrapped
                                                sx={{
                                                    fontSize: 18,
                                                }}
                                            />
                                        </Tabs>
                                    }
                                    action={
                                        <IconButton
                                            color="primary"
                                            onMouseDown={handleMouseDown}
                                            onClick={handleCloseDevDialog}
                                        >
                                            <Close />
                                        </IconButton>
                                    }
                                />
                                <CardContent>
                                    {
                                        loadDevicesList ? <CardHeader
                                            avatar={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Skeleton
                                                        variant="rounded"
                                                        sx={{
                                                            width: 80,
                                                            height: 80
                                                        }}
                                                    />
                                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                        <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: 50 }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: 50 }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: 50 }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: 50 }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            } /> :
                                            devices.length > 0 ?
                                                <>
                                                    <Box sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        maxHeight: 300,
                                                        overflowY: "scroll"
                                                    }} >
                                                        {devices
                                                            .map((device) => (
                                                                <FormControlLabel
                                                                    key={device.deviceuuid}
                                                                    control={<Checkbox
                                                                        checked={selectedDevicestoAssign.some(
                                                                            (d) => d.id === device.deviceuuid && d.macid === device.decmacid
                                                                        )}
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            const deviceId = device.deviceuuid;
                                                                            const deviceName = device.devicename;
                                                                            const deviceMacId = device.decmacid;
                                                                            if (checked) {
                                                                                setSelectedDevicetoAssign([
                                                                                    ...selectedDevicestoAssign,
                                                                                    { id: deviceId, name: deviceName, macid: deviceMacId },
                                                                                ]);
                                                                            } else {
                                                                                setSelectedDevicetoAssign((prevState) =>
                                                                                    prevState.filter(
                                                                                        (d) => d.id !== deviceId || d.macid !== deviceMacId
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                        value={device.deviceuuid}
                                                                    />}
                                                                    label={<>
                                                                        <CardHeader
                                                                            avatar={
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Avatar
                                                                                        variant='rounded'
                                                                                        alt={device.devicename}
                                                                                        src={device.deviceimageName}
                                                                                        sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                                        <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                            <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                                {device.devicename}
                                                                                            </Typography>
                                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                                {device.decmacid}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            } />

                                                                    </>}
                                                                />
                                                            ))}
                                                    </Box>
                                                </>
                                                : <center>
                                                    No {secureLocalStorage.getItem("DeviceName")} Found
                                                </center>
                                    }
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseDevDialog} fullWidth >Cancel</Button>
                                    <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleAssignDeviceToBed} fullWidth >Submit</Button>
                                </CardActions>
                            </Card>
                        </Dialog>
                    </div>

                </Card>
            </Container>
        </div>
    );
};

export default ManagePatient;
